/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import { graphql } from 'gatsby'
import { Listing, Wrapper, SEO, Header, Timeline } from '../components'
import website from '../../config/website'

const CatWrapper = Wrapper.withComponent('main')
export default ({
  pageContext,
  pageContext: { locale, slug },
  data: {
    artworks: { edges: artworksNodes },
    filterOrder: { edges: [
      { node: { data: { items: filterOrderedItems } } }
    ] }
  },
  location
}) => {
  const context = { slug: slug };
  const orderedArtworksNodes = filterOrderedItems.map((i) => {
    let nodeIndex = artworksNodes.findIndex((n) => n.node.uid === i.item.uid)
    return {
      node: {
        data: artworksNodes[nodeIndex].node.data,
        uid: i.item.uid
      }
    }
  });
  
  return (
    <>
      <SEO title={`Works of ${pageContext.uid}, Disch Rémy`} pathname={location.pathname} locale={locale} />
      <Header {...context} />
      <CatWrapper className={`${slug === 'works' ? 'timeline' : ''}`} id={website.skipNavId}>
        <Timeline {...pageContext} />
        <Listing posts={orderedArtworksNodes} />
      </CatWrapper> 
    </>
  )
}

export const pageQuery = graphql`
  query FilterPage($uid: Date!, $locale: String!) {
    artworks: allPrismicArtwork(filter: { lang: { eq: $locale}, data: { year: { uid: { eq: $uid} } } }) {
      edges {
        node {
          uid
          data {
            meta_description
            meta_title
            title {
              html
              text
            }
            type
            available
            item {
              alt
              localFile {
                childImageSharp {
                  fixed(height: 800, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            categories {
              ... on PrismicArtworkDataCategories {
                category {
                  uid
                }
              }
            }
            year {
              ... on PrismicArtworkDataYear {
                uid
              }
            }
          }
        }
      }
    }
    filterOrder: allPrismicFilters(
      filter: {
        lang: { eq: $locale }
        uid: { eq: $uid }
      }
    ) {
      edges {
        node {
          uid
          data {
            items {
              ... on PrismicFiltersDataItems {
                item {
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`;